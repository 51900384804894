<template>
  <footer class="footer">
    <div>
      <div class="links">
        <router-link to="/home/main">{{ $t("common.home") }}</router-link>
        <router-link to="/home/character">{{
          $t("common.character")
        }}</router-link>
        <!-- <router-link to="/">Social Media</router-link> -->
      </div>
    </div>
    <hr />
    <div class="media">
      <div class="sns-side-bar-under">
        <div class="sns-icon">
          <a :href="urls.patreon" target="_blank">
            <font-awesome-icon ref="patreon" icon="fa-brands fa-patreon" />
          </a>
        </div>
        <div class="sns-icon">
          <a :href="urls.twitter" target="_blank">
            <font-awesome-icon icon="fa-brands fa-twitter" />
          </a>
        </div>
        <div class="sns-icon">
          <a :href="urls.gumroad" target="_blank">
            <img class="thirdPartyIcon" :src="gumroadIcon" />
          </a>
        </div>
        <div class="sns-icon">
          <a :href="urls.instagram" target="_blank">
            <font-awesome-icon icon="fa-brands fa-instagram" />
          </a>
        </div>
        <div class="sns-icon">
          <a :href="urls.facebook" target="_blank">
            <font-awesome-icon icon="fa-brands fa-facebook" />
          </a>
        </div>
      </div>
    </div>
    <p>Copyright Tang Tang© All Rights Reserved. Designed by Tang Tang.</p>
  </footer>
</template>
<script>
import store from "../store/index";
import gumroad from "../assets/img/gumroad.png";
export default {
  data() {
    return {
      urls: store.state.snsUrl,
      gumroadIcon: gumroad,
      isOn: false,
    };
  },
};
</script>
<style lang="scss" scoped>
//element-ui
.footer {
  box-sizing: border-box;
  padding: 40px;
  max-width: 1400px;
  height: 300px;
  color: #fff;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 40px;
  @media (max-width: 600px) {
    display: none;
  }
  & > * + * {
    margin-top: 20px;
  }
  & > div {
    flex: 1;
  }
  & > hr {
    width: 100%;
  }
  & > p {
    text-align: center;
  }
}
.links {
  display: flex;
  flex-direction: column;
  // margin: 0 auto;
  @media (max-width: 600px) {
    display: none;
  }
  & > * + * {
    margin-top: 20px;
  }
}
.media {
  align-self: center;
  & > button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: transparent;

    background-position: center;
    background-size: 20px 20px;
    background-repeat: no-repeat;
    border: 1px solid #fff;
    margin-left: 12px;
    &:nth-child(1) {
      background-image: url("../assets/img/lineIcon.svg");
      margin-left: 0;
    }
    &:nth-child(2) {
      background-image: url("../assets/img/fbIcon.svg");
    }
    &:nth-child(3) {
      background-image: url("../assets/img/igIcon.svg");
    }
    &:nth-child(4) {
      background-image: url("../assets/img/ytIcon.svg");
    }
  }
}
.sns-side-bar-under {
  z-index: 10;
  // TODO: set color changed when moved out the range of main image.
  color: white;
  font-size: 25px;
  height: 80px;
  // width: 100%;
  .sns-icon{
    display: inline-block;
    margin-right: 12px;
    width: 25px;
    .thirdPartyIcon {
      width: 100%;
    }
  }
  .breathing-state {
    color: #ba49b5;
    transition: color .5s;
  }
  .breathing-state-off {
    color: white;
    transition: color .5s;
  }
}
</style>
