import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
Vue.prototype.$http = axios;

Vue.use(Vuex)
var isTaiwan = false
// get user location
await axios.get("https://ipinfo.io")
  .then(response => {
    if (response.data.country == 'TW') {
      isTaiwan = true
    }
  })
  .catch(error => (console.debug('Can not send ip request')))


export default new Vuex.Store({
  state: {
    isTaiwan: isTaiwan,
    notice: '',
    img: [
      '/img/1.jpg',
      '/img/2.jpg',
      '/img/3.jpg',
      '/img/4.jpg'
    ],
    admin: {
      adminName: "vccc",
      id: "10021",
      login: false,
    },
    hotdata: [],
    newdata: [],
    myGoodsList: [],
    username: '',
    address: '',
    money: 0,
    login: false,
    adminLogin: false,
    register: false,
    snsUrl: {
      twitter: 'https://twitter.com/tangtangmonster/',
      instagram: 'https://www.instagram.com/tangtangmonster/',
      facebook: 'https://www.facebook.com/tangtangmonster7/',
      patreon: 'https://www.patreon.com/TangTang/',
      gumroad: 'https://tangmonster.gumroad.com/',
      youtube: 'https://www.youtube.com/channel/UC7DMgDdF7h4aQuj-nD2YwFw',
      twitch: 'https://www.twitch.tv/tangtangmonster7'
    }
  },
  mutations: {
    gethotdata(state, params) {
      console.log(params);
      state.hotdata = params
    },
    getnewdata(state, params) {
      state.newdata = params;
      console.log(state.newdata);
    },
    getuserdata(state, params) {
      console.log(params);
      var userdata = params[0].filter(item => {
        return item.username === params[1];
      })
      console.log(userdata);
      state.username = userdata[0].username;
      state.address = userdata[0].address;
      state.money = userdata[0].money;

    },
    pushToMyList(state, array) {
      state.myGoodsList.push(array);
      // console.log(state.myGoodsList);
    },
    deleteMyGoods(state, index) {
      let arr = state.myGoodsList;
      arr.splice(index, 1);
      state.myGoodsList = arr;
      // console.log(state.myGoodsList);
    },
    clearMyGoodList(state) {
      state.myGoodsList = [];
    },
    updateMoney(state, money) {
      state.money = money;
      console.log(state.money);
    }
  },
  actions: {
    getHotData(context) {
      context.commit('gethotdata', [])
      // axios.get("/localApi/hotData")
      //   .then(res => {
      //     context.commit('gethotdata', res.data.data)
      //   })
    },
    getNewData(context) {
      context.commit('getnewdata', [])
      // axios.get("/localApi/newData").then(res =>{
      //   context.commit('getnewdata',res.data.data)
      // })
    },
    getUserData(context, name) {
      axios.get("/api/getUser").then(res => {
        context.commit('getuserdata', [res.data, name]);
      })
    }

  }
})
