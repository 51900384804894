<template>
<Transition>
  <div class="sns-side-bar">
    <div class="sns-icon">
      <a :href="urls.patreon" target="_blank">
        <font-awesome-icon ref="patreon" icon="fa-brands fa-patreon" />
      </a>
    </div>
    <div class="sns-icon">
      <a :href="urls.twitter" target="_blank">
        <font-awesome-icon icon="fa-brands fa-twitter" />
      </a>
    </div>
    <div class="sns-icon">
      <a :href="urls.gumroad" target="_blank">
        <img class="thirdPartyIcon" :src="gumroadIcon">
      </a>
    </div>
    <div class="sns-icon">
      <a :href="urls.instagram" target="_blank">
        <font-awesome-icon icon="fa-brands fa-instagram" />
      </a>
    </div>
    <div class="sns-icon">
      <a :href="urls.facebook" target="_blank">
        <font-awesome-icon icon="fa-brands fa-facebook" />
      </a>
    </div>
    <div class="sns-icon">
      <a :href="urls.twitch" target="_blank">
        <font-awesome-icon icon="fa-brands fa-twitch" />
      </a>
    </div>
    <div class="sns-icon">
      <a :href="urls.youtube" target="_blank">
        <font-awesome-icon icon="fa-brands fa-youtube" />
      </a>
    </div>
  </div>
</Transition>
</template>
      
<script>
// @ is an alias to /src

// import { mapstate } from "vuex";
import { isDevImg } from '../utils/index'

import store from '../store/index'
import gumroad from '../assets/img/gumroad.png'
export default {
  name: "home",
  components: {},
  data() {
    return {
      urls: store.state.snsUrl,
      gumroadIcon: gumroad,
      isOn: false
    }
  },
  mounted() {
    this.breathingP()
  },
  methods: {
    breathingP() {
      const pIcon = this.$refs.patreon
      if (pIcon && pIcon.classList) {
        if (this.isOn) {
           pIcon?.classList.remove('breathing-state')
           pIcon?.classList.add('breathing-state-off')
        } else {
          pIcon?.classList.remove('breathing-state-off')
          pIcon?.classList.add('breathing-state')
        }
        this.isOn = !this.isOn
      }
      setTimeout(() => {
        this.breathingP()
      }, 1000);
    },
  },
};
</script>
<style lang="scss" rel='stylesheet/scss'>
.sns-side-bar {
  z-index: 10;
  // TODO: set color changed when moved out the range of main image.
  position: fixed;
  right: 25px;
  top: 45%;
  color: white;
  font-size: 25px;
  height: 80px;
  // width: 100%;
  .sns-icon{
    margin-top: 10px;
    width: 25px;
    .thirdPartyIcon {
      width: 100%;
    }
  }
  .breathing-state {
    color: #ba49b5;
    transition: color .5s;
  }
  .breathing-state-off {
    color: white;
    transition: color .5s;
  }
}
</style>